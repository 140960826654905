<template>
  <div id="login">
    <form @submit.prevent="login()">
      <div id="title">
        <h1>Inloggen</h1>
        <a href="#" @click.prevent="changeView">Ik heb nog geen account</a>
      </div>
      <template>
        <p v-if="this.error.length > 0" class="error">{{ this.error }}</p>
      </template>
      <template>
        <p v-if="this.msg.length > 0" class="msg">{{ this.msg }}</p>
      </template>
      <div class="field">
        <label for="email">E-mailadres</label> <br />
        <input type="email" id="email" v-model="email" required />
      </div>
      <div class="field">
        <label for="password">Wachtwoord</label> <br />
        <input type="password" id="password" v-model="password" required />
        <a href="#" @click.prevent="forgetPassword">Wachtwoord vergeten</a>
      </div>
      <div class="field">
        <button type="submit">INLOGGEN</button>
      </div>
    </form>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      error: "",
      msg: ""
    };
  },
  methods: {
    changeView() {
      this.$emit("changeview");
    },
    forgetPassword() {
      const vm = this;
      firebase.auth()
        .sendPasswordResetEmail(vm.email.toLowerCase())
        .then(() => {
          this.err = "";
          this.msg = "E-mail werd verzonden naar " + this.email.toLowerCase();
        })
        .catch((error) => {
          this.msg = "";
          this.error = error.message;
        });
    },
    login() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          this.$router.replace({
            name: "Home",
          });
        })
        .catch((err) => {
          this.msg = "";
          this.error = err.message;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.field {
  margin: 25px auto;
  width: 60%;
}

label {
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
}

input {
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
  width: 100%;
  padding: 15px 5px;
}

h1 {
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
}

button {
  width: 100%;
  padding: 15px;
  background: #1a6ac1;
  color: #ffffff;
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
  border: none;
  cursor: pointer;
}

#title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}

a {
  color: #1a6ac1;
  text-decoration: none;
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
}

.error {
  background: #fe3256;
  width: 60%;
  padding: 15px;
  text-align: center;
  margin: 10px 0;
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
}

.msg {
  background: #1fe71f;
  width: 60%;
  padding: 15px;
  text-align: center;
  margin: 10px 0;
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
}

@media (max-width: 700px) {
  .field,
  #title,
  .error {
    width: 80%;
  }
}
</style>