<template>
  <div id="app-header">
    <div id="header">
      <app-header-logo></app-header-logo>
      <app-header-nav></app-header-nav>
    </div>
  </div>
</template>

<script>
import AppHeaderLogo from "./AppHeaderLogo.vue";
import AppHeaderNav from "./AppHeaderNav.vue";

export default {
  name: "AppHeader",
  components: {
    AppHeaderLogo,
    AppHeaderNav,
  },
};
</script>

<style lang="scss" scoped>
#app-header {
  background: #ffffff;
  position: fixed;
  width: 100%;
  box-shadow: 0 5px 15px #c0c0c0;
}

#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: auto;
  padding: 10px;
}

@media (max-width: 700px) {
  #header {
    width: 100%;
  }
}
</style>