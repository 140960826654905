<template>
  <div id="login">
    <form @submit.prevent="register">
      <div id="title">
        <h1>Account aanmaken</h1>
        <a href="#" @click.prevent="changeView">Ik heb al een account</a>
      </div>
      <template>
        <p v-if="this.error.length > 0" class="error">{{ this.error }}</p>
      </template>
      <div class="field">
        <label for="firstname">Voornaam*</label> <br />
        <input type="text" id="firstname" v-model="firstname" required />
      </div>
      <div class="field">
        <label for="lastname">Familienaam*</label> <br />
        <input type="text" id="lastname" v-model="lastname" required />
      </div>
      <div class="field">
        <label for="email">E-mailadres*</label> <br />
        <input type="email" id="email" v-model="email" required />
      </div>
      <div class="field">
        <label for="new-password">Wachtwoord*</label> <br />
        <input
          type="password"
          id="new-password"
          v-model="newPassword"
          required
        />
      </div>
      <div class="field">
        <label for="repeat-password">Wachtwoord herhalen*</label> <br />
        <input
          type="password"
          id="repeat-password"
          v-model="repeatPassword"
          required
        />
      </div>
      <div class="field">
        <button type="submit">ACCOUNT AANMAKEN</button>
      </div>
    </form>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  name: "Login",
  data() {
    return {
      firstname: "",
      lastname: "",
      email: "",
      newPassword: "",
      repeatPassword: "",
      error: "",
    };
  },
  methods: {
    changeView() {
      this.$emit("changeview");
    },
    register() {
      if (this.newPassword == this.repeatPassword) {
        firebase
          .auth()
          .createUserWithEmailAndPassword(this.email.toLowerCase(), this.newPassword)
          .then((data) => {
            data.user
              .updateProfile({
                displayName: this.firstname + " " + this.lastname,
              })
              .then(() => {
                firebase
                  .firestore()
                  .collection("users")
                  .doc(this.email.toLowerCase())
                  .set({
                    firstname: this.firstname,
                    lastname: this.lastname,
                    registrations: [],
                  })
                  .then(() => {
                    this.$router.replace({
                      name: "Home",
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                    this.error = err.message;
                  });
              });
          })
          .catch((err) => {
            this.error = err.message;
          });
      } else {
        this.error = "Wachtwoorden komen niet overeen";
        this.newPassword = "";
        this.repeatPassword = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.field {
  margin: 25px auto;
  width: 60%;
}

label {
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
}

input {
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
  width: 100%;
  padding: 15px 5px;
}

h1 {
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
}

button {
  width: 100%;
  padding: 15px;
  background: #1a6ac1;
  color: #ffffff;
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
  border: none;
  cursor: pointer;
}

#title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}

a {
  color: #1a6ac1;
  text-decoration: none;
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
}

.error {
  background: #fe3256;
  width: 60%;
  padding: 15px;
  text-align: center;
  margin: 10px 0;
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
}

@media (max-width: 700px) {
  .field,
  #title,
  .error {
    width: 80%;
  }
}
</style>