<template>
  <div id="letsgo">
    <h1>Laten we van start gaan</h1>
    <h2>
      <span class="step">1.</span> Maak een account aan of log in
    </h2>
    <h2>
      <span class="step">2.</span> Schrijf je kinderen in
    </h2>
    <h2>
      <span class="step">3.</span> Op 24 juni verdelen we de bubbels. Ten laatste 27 juni ontvang je een bevestigingsmail.
    </h2>
    <router-link :class="'button'" to="/register">
      AAN DE SLAG!
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Letsgo",
};
</script>

<style lang="scss" scoped>
h1 {
    font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
    margin-bottom: 25px;
}

h2 {
    font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
    margin-bottom: 25px;
}

.button {
  background: #1a6ac1;
  padding: 15px;
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
  color: #fefefe;
  text-decoration: none;
  margin: 10px 0;
  display: block;
  width: 50%;
  text-align: center;
}
</style>