<template>
  <div id="app-header-nav">
    <div id="nav">
      <ul class="nav-list">
        <router-link to="/" class="nav-list__item">
          <li>HOME</li>
        </router-link>
        <template v-if="user.loggedIn">
          <!-- <router-link to="/inschrijven" class="nav-list__item">
            <li>INSCHRIJVEN</li>
          </router-link> -->
          <router-link to="account" class="nav-list__item">
            <li>MIJN PROFIEL</li>
          </router-link>
          <a href="#" @click.prevent="logout()" class="nav-list__item">
            <li>UITLOGGEN</li>
          </a>
        </template>
        <template v-else>
          <router-link to="login" class="nav-list__item">
            <li>INLOGGEN</li>
          </router-link>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  name: "AppHeaderNav",
  data() {
    return {
      user: this.$store.state.user
    }
  },
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({
            name: 'Login'
          });
        });
    },
  },
  // computed: {
  //   user() {
  //     return this.$store.getters.user;
  //   },
  // },
};
</script>

<style lang="scss" scoped>
a {
  color: black;
  text-decoration: none;
}

.nav-list {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style: none;

  &__item {
    margin: 0 15px;
    font-family: "Ubuntu", Arial, Helvetica, sans-serif;
    transition: 0.1s linear;
    text-transform: uppercase;

    &:hover {
      color: #1a6ac1;
    }
  }
}

@media (max-width: 700px) {
  .nav-list {
    &__item {
      margin: 0 5px;
      padding: 5px;
    }
  }
}
</style>