<template>
  <div id="account">
    <h1>Account</h1>
    <section id="data">
      <h2>Gegevens</h2>
      <h3>Naam</h3>
      <p>{{ user.data.displayName }}</p>
      <h3>E-mailadres</h3>
      <p>{{ user.data.email }}</p>
    </section>
    <section id="registrations">
      <h2>Inschrijvingen ({{ registrations.length }})</h2>
      <div
        class="registration"
        v-for="reg in registrations"
        :key="reg.firstname"
      >
        <h3>{{ reg.firstname }} {{ reg.lastname }}</h3>
        <h4>Geboortedatum</h4>
        <p>{{ formatBirthday(reg.birthday) }}</p>
        <!-- <template v-if="reg.friend1.length > 0 || reg.friend2 > 0">
          <h4>Gekozen vriend(in)en</h4>
          <p>{{ reg.friend1 }}</p>
          <p>{{ reg.friend2 }}</p>
        </template> -->
        <h4>Aanwezigheden ({{ reg.days.length }})</h4>
        <p v-for="day in reg.days" :key="day">
          {{ day }}
        </p>
        <!-- <template v-if="reg.bubbles">
          <h4>Keuze bubbels</h4>
          <p v-for="b in reg.bubbles" :key="b">
            {{ b }}
          </p>
        </template> -->
        <h4>Ingeschreven op</h4>
        <p>{{ formatDatetime(reg.timestamp) }}</p>
      </div>
    </section>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  name: "Account",
  data() {
    return {
      registrations: [],
    };
  },
  methods: {
    formatDate(d) {
        const date = new Date(d);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    },
    formatBirthday(d) {
        const date = new Date(d);
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    },
    formatDatetime(d) {
        const date = new Date(d);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${this.doubleDigits(date.getHours())}:${this.doubleDigits(date.getMinutes())}:${this.doubleDigits(date.getSeconds())}`;
    },
    doubleDigits(digit) {
        return digit <= 9 ? '0' + digit : digit;
    },
    getRegistrations() {
      const t = this;
      firebase
        .firestore()
        .collection("users")
        .doc(this.$store.getters.user.data.email)
        .get()
        .then((doc) => {
          if (doc.exists) {
            t.registrations = doc.data().registrations;
          } else {
            console.log("fout");
          }
        });
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  mounted() {
    const t = this;
    setTimeout(() => {
      t.getRegistrations();
    }, 800);
  },
};
</script>

<style lang="scss" scoped>
h1,
h2,
h3,
h4,
p {
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
  margin: 5px 0;
}

section,
.registration {
  margin: 15px 0;
  background: #e9e9e9;
  padding: 15px;
}
</style>