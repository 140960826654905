<template>
  <div id="auth">
      <login @changeview="login = false" v-if="login" />
      <register @changeview="login = true" v-else />
  </div>
</template>

<script>
import Login from '../components/AppAuth/Login.vue';
import Register from '../components/AppAuth/Register.vue';

export default {
  name: "Auth",
  components: {
      Login,
      Register
  },
  data() {
    return {
        login: this.$route.path == '/login'
    };
  },
};
</script>

<style lang="scss" scoped>
#auth {
    width: 100%;
}
</style>