<template>
  <div id="dashboard">
    <div v-if="this.$route.path == '/success' && showMsg" class="msg">
      <p>Uw inschrijving werd goed ontvangen</p>
    </div>
    <div id="c">
      <!-- <router-link to="/inschrijven" :class="'card'">
        <h2>Kind inschrijven</h2>
      </router-link> -->
      <router-link to="/account" :class="'card'">
        <h2>Mijn profiel</h2>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppDashboard",
  data() {
      return {
          showMsg: true
      }
  },
  mounted() {
      const t = this;
      setTimeout(() => {
          t.showMsg = false
      }, 5500)
  }
};
</script>

<style lang="scss" scoped>
#c {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.msg {
    padding: 15px;
    background: #1ec51e;
    font-family: "Ubuntu", Arial, Helvetica, sans-serif;
    color: #fefefe;
    animation: fadeout;
    animation-duration: 0.5s;
    animation-delay: 5s;
    animation-iteration-count: 1;
}

.card {
  padding: 25px;
  text-decoration: none;
  background: #e9e9e9;
  width: 100%;
  margin: 15px 15px 15px 0;
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
  color: #333333;
  transition: 0.1s linear;

  &:hover {
    color: #1a6ac1;
    box-shadow: 3px 2px 8px #b0b0b0;
  }
}

@keyframes fadeout {
    0% {
        opacity: 100%;
    }
    100% {
        opacity: 0%;
    }
}
</style>