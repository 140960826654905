import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from "firebase";

Vue.config.productionTip = false

const firebaseConfig = {
  apiKey: "AIzaSyAYyS1JDGTNxEbbqol3lZyGEWOexVhfyYA",
  authDomain: "speelbergadministrationmanager.firebaseapp.com",
  databaseURL: "https://speelbergadministrationmanager.firebaseio.com",
  projectId: "speelbergadministrationmanager",
  storageBucket: "speelbergadministrationmanager.appspot.com",
  messagingSenderId: "606106261360",
  appId: "1:606106261360:web:90237b94fe5df3ad6261b5"
};

firebase.initializeApp(firebaseConfig);

firebase.auth().onAuthStateChanged(user => {
  setTimeout(() => {
    store.dispatch("fetchUser", user);
  }, 500);
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
