<template>
  <div class="home">
    <app-dashboard v-if="user.loggedIn"></app-dashboard>
    <letsgo v-else />
  </div>
</template>

<script>
import AppDashboard from '../components/Dashboard/AppDashboard.vue';
import Letsgo from '../components/Letsgo/Letsgo.vue'

export default {
  name: 'Home',
  components: {
    Letsgo,
    AppDashboard
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  }
}
</script>
