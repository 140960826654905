<template>
  <div id="app">
    <app-header></app-header>
    <div id="container">
      <router-view />
    </div>
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader/AppHeader";

export default {
  components: {
    AppHeader,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Ubuntu&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#container {
  width: 80%;
  margin: auto;
  padding: 180px 10px 0 10px;
}

@media (max-width: 700px) {
  #container {
    width: 95%;
    padding-top: 80px;
  }
}
</style>
