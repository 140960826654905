<template>
  <div id="app-logo">
    <router-link to="/">
      <div id="logo">
        <img id="logo__img" src="@/assets/logo_circle.png" alt="" />
        <div id="logo__text">
          <h1>Speelplein De Speelberg VZW</h1>
          <p>Inschrijven</p>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "AppHeaderLogo",
};
</script>

<style lang="scss" scoped>
a {
  color: black;
  text-decoration: none;
}

#logo {
  display: flex;
  justify-content: center;
  align-items: center;

  &__img {
    width: 70px;
    height: auto;
    margin: 10px 15px;
  }

  &__text {
    font-family: "Ubuntu", Arial, Helvetica, sans-serif;

    p {
        color: #999999;
        text-align: right;
    }
  }
}

@media (max-width: 700px) {
  #logo {
    display: none;
  }
}
</style>