<template>
  <div id="registrations">
    <form @submit.prevent="submit()">
      <div class="title">
        <h1>Nieuwe inschrijving</h1>
      </div>
      <template>
        <p v-if="this.error.length > 0" class="error">{{ this.error }}</p>
      </template>
      <div class="title">
        <h2>Gegevens kind*</h2>
      </div>
      <div class="field">
        <label for="firstname">Voornaam*</label> <br />
        <input type="text" id="firstname" v-model="firstname" required />
      </div>
      <div class="field">
        <label for="lastname">Familienaam*</label> <br />
        <input type="text" id="lastname" v-model="lastname" required />
      </div>
      <div class="field">
        <label for="birthday">Geboortedatum*</label> <br />
        <input
          type="date"
          id="birthday"
          v-model="birthday"
          required
        />
      </div>
      <div class="field">
        <div class="title">
          <h2>Aanwezige dagen*</h2>
        </div>
        <template v-for="day in days">
          <input
            :key="day.date + '1'"
            type="checkbox"
            v-model="day.checked"
            :id="day.date"
            :value="day.date"
          />
          <label :key="day.date + '2'" :for="day.date">{{
            formatDate(day.date)
          }}</label>
          <br :key="day.date + '3'" />
        </template>
      </div>
      <div class="field">
        <label for="friend1">Eerste vriend(in)</label> <br />
        <input type="text" id="friend1" v-model="friend1" />
      </div>
      <div class="field">
        <label for="friend2">Tweede vriend(in)</label> <br />
        <input type="text" id="friend2" v-model="friend2" />
      </div>
      <div class="field">
        <label for="friend3">Derde vriend(in)</label> <br />
        <input type="text" id="friend3" v-model="friend3" />
      </div>
      <div class="field">
        <button type="submit">INSCHRIJVEN</button>
      </div>
    </form>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  name: "Registrations",
  data() {
    return {
      firstname: "",
      lastname: "",
      birthday: "",
      friend1: "",
      friend2: "",
      friend3: "",
      days: [
        {
          date: "2021-07-5",
          checked: false,
        },
        {
          date: "2021-07-6",
          checked: false,
        },
        {
          date: "2021-07-7",
          checked: false,
        },
        {
          date: "2021-07-8",
          checked: false,
        },
        {
          date: "2021-07-9",
          checked: false,
        },
        {
          date: "2021-07-12",
          checked: false,
        },
        {
          date: "2021-07-13",
          checked: false,
        },
        {
          date: "2021-07-14",
          checked: false,
        },
        {
          date: "2021-07-15",
          checked: false,
        },
        {
          date: "2021-07-16",
          checked: false,
        },
      ],
      error: "",
    };
  },
  methods: {
    formatDate(d) {
      const date = new Date(d);
      const days = ["Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag"];
      const da = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      const month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      return `${days[date.getDay() - 1]} ${da}-${month}-${date.getFullYear()}`;
    },
    formatNumbers() {
      return 1;
    },
    getDays() {
      let days = [];
      for (let i = 0; i < this.days.length; i++) {
        if (this.days[i].checked) {
          days.push(this.days[i].date);
        }
      }
      return days;
    },
    checkDays() {
      for (let i = 0; i < this.days.length; i++) {
        if (this.days[i].checked) {
          return true;
        }
      }
      return false;
    },
    submit() {
      if (this.checkDays()) {
        firebase
          .firestore()
          .collection("users")
          .doc(this.$store.getters.user.data.email)
          .update({
            registrations: firebase.firestore.FieldValue.arrayUnion({
              firstname: this.firstname,
              lastname: this.lastname,
              birthday: this.birthday,
              friend1: this.friend1,
              friend2: this.friend2,
              friend3: this.friend3,
              days: this.getDays(),
              //bubbles: this.getBubbles(),
              timestamp: Date.now(),
            }),
          })
          .then(() => {
            this.$router.replace({
              name: "Success",
            });
          })
          .catch((err) => (this.error = err.message));
      } else {
        this.error = "Je moet minstens 1 dag aanduiden";
      }
    }
  },
}
</script>

<style lang="scss" scoped>
form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.field {
  margin: 15px auto;
  width: 60%;
}

label {
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
  font-size: 18px;
}

label[disabled] {
  color: #999999;
}

input {
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
  width: 100%;
  padding: 15px 5px;
}

input[type="checkbox"] {
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
  width: 4% !important;
  padding: 0 !important;
  margin: 5px 0;
}

h1,
h2 {
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
}

button {
  width: 100%;
  padding: 15px;
  background: #1a6ac1;
  color: #ffffff;
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
  border: none;
  cursor: pointer;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}

a {
  color: #1a6ac1;
  text-decoration: none;
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
}

.error {
  background: #fe3256;
  width: 60%;
  padding: 15px;
  text-align: center;
  margin: 10px 0;
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
}

@media (max-width: 700px) {
  .field,
  .title,
  .error {
    width: 80%;
  }
}
</style>