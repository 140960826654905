<template>
  <div class="registration">
    <registration></registration>
  </div>
</template>

<script>
import Registration from '../components/Registrations/Registration.vue';

export default {
  name: 'Home',
  components: {
    Registration
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  }
}
</script>
