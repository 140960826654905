import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Auth from '../views/Auth'
import Registration from '../views/Registration'
import Account from '../views/Account'
import firebase from "firebase";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/success',
    name: 'Success',
    component: Home,
    meta: {
      authRequired: true,
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Auth
  },
  {
    path: '/login',
    name: 'Login',
    component: Auth
  },
  {
    path: '/inschrijven',
    name: 'Registration',
    component: Registration,
    meta: {
      authRequired: true,
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      authRequired: true,
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.authRequired)) {
    if(firebase.auth().currentUser) {
      next();
    } else {
      next({
        path: '/login'
      });
    }
  } else {
    next();
  }
});

export default router
